.employerPage {
    max-width: 1248px;
    margin: 0 auto;
    .hero-section {
        display: flex;
        align-items: center;
        border-radius: 24px;
        background-color: #fbad84;
        margin-bottom: 120px;
        margin-top: 38px;
        background-repeat: no-repeat;
        position: relative;
        background-size: cover;
        background-position: 100%;

        .col-1 {
            padding: 60px 0 60px 60px;
            width: 50%;
            h1 {
                color: var(--dark, #1e1e2f);
                font-family: Space Grotesk;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 114.5%; /* 54.96px */
                margin-bottom: 16px;
            }
            p {
                color: var(--dark-2, #344054);
                font-family: Space Grotesk;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 24px;
            }
        }
    }
    &__partnerships {
        display: flex;
        align-items: center;
        gap: 60px;
        margin-bottom: 60px;
        flex-direction: column;
        width: 100%;
        span {
            font-size: 24px;
            font-weight: 600;
            color: var(--Text-Secondary);
        }
    }
    &__benefits {
        margin-top: 70px;
        margin-bottom: 141px;
        h2 {
            color: var(--dark, #1e1e2f);
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 24px;
        }
        .carousel-dots {
            display: none;
        }
        &-slider {
            display: none;
        }
        &-container {
            display: grid;
            gap: 24px;
            margin-top: 40px;
            justify-content: space-between;
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: 100px;
        }
        &-box {
            display: flex;
            align-items: center;
            flex-direction: column;
            div {
                height: 56px;
                margin-block: 60px 22px;
                color: var(--Text-Primary);
                font-size: 28px;
                font-weight: 600;
                line-height: 100.9%;
                text-align: start;
                align-self: self-start;
            }
            p {
                font-size: 20px;
                font-weight: 400;
                color: var(--Text-secondary);
                text-align: start;
                align-self: self-start;
            }
        }
    }
    &__talent {
           margin-bottom: 141px;
        h2 {
            color: var(--dark, #1e1e2f);
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 24px;
        }
        &-container {
            margin-top: 40px;
            display: flex;
            // justify-content: space-between;
            gap: 24px;
        }
    }
    &__howItWorks {
        margin-top: 98px;
        margin-bottom: 165px;

        h2 {
            color: var(--dark, #1e1e2f);
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 24px;
        }
        button {
            outline: none;
            border: none;
            padding: 14px 16px;
            border-radius: 24px;
            background-color: var(--Text-Primary);
            font-size: 14.5px;
            font-weight: 500;
            line-height: 20px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            margin: 0 auto;
            margin-top: -60px;
        }
    }
}

@media only screen and (max-width: 1360px) {
    .employerPage {
        width: 100%;
        padding: 0 30px;
    }
}

@media only screen and (max-width: 1200px) {
    .employerPage .hero-section .col-1 {
        width: 60%;
    }
    .employerPage__talent-container .ourTalent {
        width: 100%;
        flex: 1;
    }
}

@media only screen and (max-width: 1150px) {
    .employerPage {
        &__benefits {
            &-slider {
                display: block;
                margin-top: 36px;
            }
            .carousel-dots {
                display: flex;
            }
            &-container {
                display: none;
            }
            &-box {
                svg {
                    margin: 0 auto;
                }
                div {
                    font-size: 34px;
                    text-align: center;
                }
                p {
                    font-size: 24px;
                    font-weight: 400;
                    text-align: center;
                    width: 60%;
                    margin: 0 auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .employerPage {
        .hero-section {
            margin-bottom: 70px;
            .col-1 {
                width: 65%;
                padding: 60px 0 42px 60px;
            }
        }
        &__partnerships {
            gap: 36px;
            svg {
                height: 42px;
                width: auto;
            }
        }
    }
}

@media only screen and (max-width: 1040px) {
    .employerPage .hero-section .col-1 h1 {
        font-size: 42px;
    }
    .employerPage__talent-container .ourTalent:first-of-type {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    .employerPage__howItWorks button {
        display: none;
    }
}

@media only screen and (max-width: 968px) {
    .employerPage .hero-section .col-1 {
        width: 60%;
        padding: 48px 0 36px 48px;
        h1 {
            font-size: 38px;
        }
        p {
            font-size: 20px;
        }
        .buttons {
            margin-top: 32px;
            a {
                width: 172px;
            }
        }
    }
}

@media only screen and (max-width: 893px) {
    .employerPage {
        .hero-section {
            margin-bottom: 48px;
            .col-1 {
                padding: 36px 0 36px 36px;
                h1 {
                    font-size: 34px;
                }
                p {
                    font-size: 18px;
                }
            }
        }
        &__partnerships {
            gap: 32px;
            svg {
                height: 36px;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .employerPage {
        .hero-section .col-1 {
            h1 {
                font-size: 30px;
            }
            p {
                font-size: 16px;
            }
            a.buttonWithArrow {
                width: 152px;

                font-size: 14px;
                height: 40px;
            }
        }
        &__talent-container {
            flex-direction: column;
            .SignUpCard {
                min-height: 360px;
            }
        }
    }
}

@media only screen and (max-width: 760px) {
    .employerPage {
        .hero-section {
            margin-bottom: 32px;
        }
        &__partnerships {
            gap: 24px;
            span {
                font-size: 20px;
            }
            svg {
                height: 28px;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .employerPage {
        .hero-section .col-1 {
            padding: 28px;
            padding-right: 0px;
            h1 {
                font-size: 22px;
            }
            p {
                font-size: 14px;
            }
            a.buttonWithArrow {
                width: 132px;
                font-size: 12px;
                height: 30px;
            }
        }
        &__benefits {
            margin-bottom: 80px;
            h2 {
                font-size: 36px;
                margin-bottom: 16px;
            }
            &-box {
                p {
                    width: 100%;
                }
            }
        }
        &__talent {
            margin-bottom: 80px;
            h2 {
                font-size: 36px;
                margin-bottom: 16px;
            }
        }
        &__howItWorks {
            margin-top: 0px;
            margin-bottom: 80px;
            h2 {
                font-size: 36px;
                margin-bottom: 16px;
            }
        }
    }
}

@media only screen and (max-width: 630px) {
    .employerPage {
        .hero-section {
            margin-top: 24;
            .col-1 {
                padding: 24px;
                padding-right: 0;
                h1 {
                    font-size: 18px;
                    margin-bottom: 8px;
                }
                p {
                    font-size: 10px;
                    width: 80%;
                }
                a.buttonWithArrow {
                    width: 116px;
                    font-size: 10px;
                    height: 24px;
                }
            }
        }
        &__partnerships {
            gap: 16px;
            margin-bottom: 32px;
            h2 {
                font-size: 32px;
                font-weight: 600;
            }

            svg {
                height: 22px;
            }
        }
        &__benefits {
            margin-top: 0px;
            h2 {
                margin-bottom: 12px;
                font-size: 32px;
                font-weight: 600;
            }
            &-slider {
                margin-top: 24px;
            }
            &-box {
                svg {
                    width: 120px;
                    height: 120px;
                }
                div {
                    margin-block: 36px 16px;
                    font-size: 28px;
                    height: auto;
                }
                p {
                    font-size: 20px;
                }
            }
        }
        &__talent {
            margin-bottom: 60px;
            h2 {
                font-size: 32px;
                font-weight: 600;
            }
            &-container {
                .SignUpCard {
                    min-height: 340px;
                    p {
                        font-size: 30px;
                    }
                }
            }
        }
        &__howItWorks {
            margin-bottom: 60px;
            h2 {
                font-size: 32px;
                margin-bottom: 16px;
            }
        }
    }
}

@media only screen and (max-width: 530px) {
    .employerPage {
        padding: 0 20px;
        .hero-section .col-1 {
            padding: 20px;
            padding-right: 0;
            h1 {
                font-size: 16px;
                margin-bottom: 8px;
            }
            p {
                margin-bottom: 0;
            }
            a.buttonWithArrow {
                margin-top: 16px;
                width: 106px;
                font-size: 9px;
                height: 22px;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .employerPage {
        .hero-section {
            margin-top: 16px;
            margin-bottom: 16px;
        }
        &__partnerships {
            margin-bottom: 24px;
            span {
                font-size: 16px;
            }
            svg {
                height: 18px;
            }
        }
        &__benefits {
            h2 {
                font-size: 24px;
                margin-bottom: 8px;
            }
            &-slider {
                margin-top: 24px;
            }
            &-box {
                svg {
                    width: 68px;
                    height: 68px;
                }
                div {
                    margin-block: 24px 8px;
                    font-size: 18px;
                    height: auto;
                }
                p {
                    font-size: 16px;
                }
            }
        }
        &__talent {
            margin-bottom: 36px;
            h2 {
                font-size: 24px;
                margin-bottom: 8px;
            }

            &-container {
                margin-top: 24px;
                gap: 16px;
                .SignUpCard {
                    min-height: 290px;
                    padding: 12px 32px;
                    p {
                        font-size: 24px;
                        font-weight: 700;

                        margin-right: 8px;
                    }
                    button {
                        margin-top: 10px;
                        padding: 12px 40px;
                    }
                }
            }
        }
        &__howItWorks {
            margin-bottom: 36px;
            h2 {
                font-size: 24px;
                margin-bottom: 8px;
            }
        }
    }
}

@media only screen and (max-width: 470px) {
    .employerPage .hero-section .col-1 {
        h1 {
            font-size: 14px;
        }
        p {
            font-size: 9px;
        }
        a.buttonWithArrow {
            margin-top: 12px;

            width: 96px;
            font-size: 8px;
            height: 18px;
        }
    }
}

@media only screen and (max-width: 410px) {
    .employerPage {
        .hero-section .col-1 {
            h1 {
                font-size: 12px;
            }
            p {
                font-size: 8px;
            }
            a.buttonWithArrow {
                width: 76px;
                font-size: 6px;
                height: 14px;
            }
        }
        &__partnerships {
            svg {
                height: 16px;
            }
        }
        &__talent {
            &-container {
                .SignUpCard {
                    min-height: 310px;
                    padding: 12px 32px;
                    p {
                        font-size: 22px;
                        font-weight: 700;

                        margin-right: 8px;
                    }
                    button {
                        margin-top: 10px;
                        padding: 12px 40px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 360px) {
    .employerPage {
        padding: 0 16px;
        .hero-section .col-1 {
            padding: 12px 0 12px 12px;
            padding-right: 0;
            h1 {
                font-size: 10px;
            }
        }
        &__partnerships {
            svg {
                height: 14px;
            }
        }
        &__benefits {
            h2 {
                font-size: 22px;
            }
            &-box {
                div {
                    margin-block: 22px 8px;
                    font-size: 16px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
        &__howItWorks {
            h2 {
                font-size: 22px;
            }
        }
    }
}
