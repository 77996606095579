// @import "../../index.scss";

.review-card {
  padding: 38px;
  border-radius: 36px;
  background: var(--very-lite-purple, #faf9ff);
  display: flex;
  flex-direction: column;
  width: 400px;
  min-height: 500px;
  gap: 60px;
  & > svg {
    height: 34px;
  }
  & > p {
    color: #111;
    font-family: Space Grotesk;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  .review-author {
    display: flex;
    margin-top: 16px;
    align-items: center;
    gap: 21px;
    margin-top: auto;
    div {
      display: flex;
      flex-direction: column;
      gap: 6.5px;
    }
    div > p:first-of-type {
      color: #111;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
    }
    div p {
      color: var(--neutral-600, #6f6c90);
      font-size: 18px;
      font-weight: 400;
    }
  }
}
.carousel-button {
  width: 6px;
  height: 6px;

  /* Secondary/Purple100 */

  background: #d0d9fa;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
}
.active-carousel-button {
  width: 16px;
  height: 6px;

  /* Secondary/Green600 */

  background: #5937cc;
  border-radius: 23px;
}
.carousel-dots {
  display: flex;
  margin: 0 auto;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  button {
    cursor: pointer;
    border: none;
  }
}

@media only screen and (max-width: 1400px) {
  .review-card {
    width: 350px;
    padding: 38px 24px;
  }
}

@media only screen and (max-width: 1150px) {
  .review-card {
    padding: 38px 24px;
    min-width: 350px;
    width: 95%;
  }
}

@media only screen and (max-width: 800px) {
  .review-card {
    padding: 38px 24px;
    min-width: 350px;
    width: 100%;
    min-height: unset;
    min-width: unset;
  }
}

@media only screen and (max-width: 500px) {
  .review-card {
    gap: 24px;
    padding: 24px;
    svg {
      height: 24px;
      width: fit-content;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
    .review-author {
      gap: 7.5px;
      img {
        width: 45px;
        height: 45px;
      }
      div {
        gap: 7px;
        p {
          &:first-of-type {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
          }
          font-size: 14px;

          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .review-card {
    gap: 22px;
    padding: 22px;
    p {
      font-size: 12px;
    }
    .review-author {
      div {
        gap: 7px;
        p {
          &:first-of-type {
            font-size: 14px;
          }
          font-size: 12px;
        }
      }
    }
  }
}
