.companies__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    img, svg{    
        max-width: 120px;
    }
    
}
@media only screen and (max-width: 1024px) {
    .companies__logo {img, svg {
        max-width: 100px;
        max-height: 50px;
        object-fit: contain;
    }}
}
@media only screen and (max-width: 500px) {
    .companies__logo {img, svg {
        max-width: 80px;
        max-height: 40px;
    }}
}
