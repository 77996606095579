.talentPage {
  max-width: 1248px;
  margin: 0 auto;

  &__howItWorks {
    margin-bottom: 40px;
    h2 {
      color: var(--Text-Primary);
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    button {
      outline: none;
      border: none;
      padding: 14px 16px;
      border-radius: 24px;
      background-color: var(--Text-Primary);
      font-size: 14.5px;
      font-weight: 500;
      line-height: 20px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      margin: 0 auto;
      margin-top: -60px;
    }
  }

  .courses-section {
    margin-bottom: 120px;
    h2 {
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }
    .courses-cards {
      margin-top: 40px;
      display: flex;
      gap: 40px;
      .card {
        padding: 40px 32px;
        border-radius: 24px;
        background: var(--very-lite-purple, #faf9ff);
        h3 {
          color: #000;
          font-family: Space Grotesk;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 100% */
          margin-block: 24px;
        }
        .chips {
          display: flex;
          flex-wrap: wrap;
          gap: 8px 11px;
          margin-bottom: 16px;
        }
        .chip {
          display: flex;
          padding: 2px 8px;
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          background: var(--gray-100, #f2f4f7);
          color: var(--gray-700, #344054);
          text-align: center;
          font-family: Space Grotesk;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
        .description {
          color: var(--344054, #344054);
          font-family: Space Grotesk;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          margin-bottom: 32px;
        }
      }
      .button-group {
        display: flex;
        gap: 16px;
      }
    }
  }

  .employers {
    margin-bottom: 120px;
    h2 {
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }
    &__container {
      display: flex;
      margin-top: 40px;
      gap: 24px;
    }
  }
  .recourses {
    margin-bottom: 120px;
    h2 {
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }
    &__container {
      display: flex;
      margin-top: 40px;
      gap: 24px;
    }
  }

  .whatYouWillGet {
    margin-bottom: 120px;
    h2 {
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }
    &__container {
      display: flex;
      gap: 24px;
      margin-top: 40px;
      justify-content: space-between;
    }
    .carousel-dots {
      display: none;
    }
    &__slider {
      display: none;
    }
    &__box {
      display: flex;
      align-items: center;
      flex-direction: column;
      div {
        height: 56px;
        margin-block: 60px 22px;
        color: var(--Text-Primary);
        font-size: 28px;
        font-weight: 600;
        line-height: 100.9%;
        text-align: start;
        align-self: self-start;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        color: var(--Text-secondary);
        text-align: start;
        line-height: 135%;
        align-self: self-start;
      }
    }
  }

  .recommended-jobs-section {
    margin-bottom: 120px;
    h2 {
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }

    .recommendedJobs__container {
      display: flex;
      gap: 20px;
      margin-top: 24px;
    }
  }
}
.hero-section {
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-color: #84bfa9;
  margin-bottom: 120px;
  margin-top: 38px;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  background-position: 100%;

  .col-1 {
    padding: 60px 0 60px 60px;
    width: 50%;
    h1 {
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 114.5%; /* 54.96px */
      margin-bottom: 16px;
    }
    p {
      color: var(--dark-2, #344054);
      font-family: Space Grotesk;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 24px;
    }
  }
}
.talent-at-work {
  margin-bottom: 120px;
  text-align: center;
  h2 {
    color: var(--text-secondry, #344054);
    font-family: Space Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 60px;
  }
  .images {
    display: flex;
    gap: 24px;

    img {
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.05) translateY(-5px);
      }
    }
  }
}

@media only screen and (max-width: 1360px) {
  .talentPage {
    width: 100%;
    padding: 0 30px;
    max-width: unset;
  }
  .talent-at-work {
    display: flex;
    flex-direction: column;
    .images {
      display: flex;
      gap: 24px;
      width: 100%;

      img {
        width: 23%;
        flex: 1;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .talentPage .hero-section .col-1 {
    width: 60%;
  }
  .employers {
    &__container {
      .verifiedEmployer {
        width: 100%;
      }
    }
  }
  .talentPage .recourses .resource {
    width: 100%;
    flex: 1;
  }
  .talentPage .recommended-jobs-section .recommendedJob {
    width: 100%;
    flex: 1;
  }
}

@media only screen and (max-width: 1150px) {
  .talentPage {
    .whatYouWillGet {
      &__slider {
        display: block;
        margin-top: 36px;
      }
      .carousel-dots {
        display: flex;
      }
      &__container {
        display: none;
      }
      &__box {
        svg {
          margin: 0 auto;
        }
        div {
          font-size: 34px;
          text-align: center;
        }
        p {
          font-size: 24px;
          font-weight: 400;
          text-align: center;
          width: 60%;
          margin: 0 auto;
        }
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .talentPage {
    .hero-section {
      margin-bottom: 70px;
      .col-1 {
        width: 65%;
        padding: 60px 0 42px 60px;
      }
    }
  }
}

@media only screen and (max-width: 1040px) {
  .talentPage .hero-section .col-1 h1 {
    font-size: 42px;
  }
  .employers {
    &__container {
      .verifiedEmployer:first-of-type {
        display: none;
      }
    }
  }
  .talentPage .recourses .resource:first-of-type {
    display: none;
  }
  .talentPage .recommended-jobs-section .recommendedJob:first-of-type {
    display: none;
  }
}

@media only screen and (max-width: 968px) {
  .talentPage .hero-section .col-1 {
    width: 60%;
    padding: 48px 0 36px 48px;
    h1 {
      font-size: 38px;
    }
    p {
      font-size: 20px;
    }
    .buttons {
      margin-top: 32px;
      a {
        width: 172px;
      }
    }
  }
}

@media only screen and (max-width: 893px) {
  .talentPage {
    .hero-section {
      margin-bottom: 48px;
      .col-1 {
        padding: 36px 0 36px 36px;
        h1 {
          font-size: 34px;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .talentPage {
    .hero-section .col-1 {
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 16px;
      }
      a.buttonWithArrow {
        width: 152px;

        font-size: 14px;
        height: 40px;
      }
    }
    .employers {
      &__container {
        flex-direction: column;

        .SignUpCard {
          min-height: 550px;
          p {
            font-size: 34px;
            margin: 0;
          }
        }
      }
    }
    .recourses__container {
      flex-direction: column;
      .SignUpCard {
        min-height: 550px;
      }
    }
    .recommendedJobs__container {
      flex-direction: column;
      .SignUpCard {
        min-height: 550px;
      }
    }
  }
}

@media only screen and (max-width: 760px) {
  .talentPage {
    .hero-section {
      margin-bottom: 32px;
    }
  }
}

@media only screen and (max-width: 720px) {
  .talentPage {
    .hero-section .col-1 {
      padding: 28px;
      padding-right: 0px;
      h1 {
        font-size: 22px;
      }
      p {
        font-size: 14px;
      }
      a.buttonWithArrow {
        width: 132px;
        font-size: 12px;
        height: 30px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    &__howItWorks {
      margin-bottom: 80px;
      h2 {
        font-size: 36px;
        margin-bottom: 16px;
      }
    }
    .whatYouWillGet {
      margin-bottom: 80px;
      h2 {
        font-size: 36px;
        margin-bottom: 16px;
      }
      &__box {
        p {
          width: 100%;
        }
      }
    }
    .recourses {
      margin-top: 0px;
      margin-bottom: 80px;
      h2 {
        font-size: 36px;
        margin-bottom: 16px;
      }
    }
    .recommended-jobs-section {
      margin-top: 0px;
      margin-bottom: 80px;
      h2 {
        font-size: 36px;
        margin-bottom: 16px;
      }

      .recommendedJobs__container {
        .SignUpCard {
          min-height: 471px;
        }
      }
    }
    .employers {
      margin-bottom: 80px;
      h2 {
        font-size: 36px;
        margin-bottom: 16px;
      }
      p.paragraph {
        width: 100%;
      }
      .SignUpCard {
        min-height: 471px;
      }
    }
  }

  .talent-at-work {
    margin-bottom: 80px;
    h2 {
      font-size: 22px;
      margin-bottom: 24px;
    }
    .images {
      gap: 12px;
    }
  }
}

@media only screen and (max-width: 630px) {
  .talentPage {
    .hero-section {
      margin-top: 24px;
      .col-1 {
        padding: 24px;
        padding-right: 0;
        h1 {
          font-size: 18px;
          margin-bottom: 8px;
        }
        p {
          font-size: 10px;
          width: 80%;
        }
        a.buttonWithArrow {
          width: 116px;
          font-size: 10px;
          height: 24px;
        }
      }
    }
    .whatYouWillGet {
      margin-bottom: 60px;

      h2 {
        font-size: 32px;
        font-weight: 600;
      }
      &__slider {
        margin-top: 24px;
      }
      &__box {
        svg {
          width: 120px;
          height: 120px;
        }
        div {
          margin-block: 36px 16px;
          font-size: 28px;
          height: auto;
        }
        p {
          font-size: 20px;
        }
      }
    }
    .recourses {
      margin-bottom: 60px;
      h2 {
        font-size: 32px;
        font-weight: 600;
      }
      .SignUpCard {
        min-height: 500px;
      }
    }
    .recommended-jobs-section {
      margin-bottom: 60px;
      h2 {
        font-size: 32px;
        font-weight: 600;
      }
      .SignUpCard p {
        font-size: 30px;
      }
    }
    .employers {
      margin-bottom: 60px;
      h2 {
        font-size: 32px;
        font-weight: 600;
      }
      .SignUpCard p {
        font-size: 30px;
      }
    }
    &__howItWorks {
      margin-bottom: 60px;
      h2 {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }
  }
  .talent-at-work {
    margin-bottom: 60px;
    h2 {
      font-size: 18px;
      margin-bottom: 16px;
    }
    .images {
      gap: 10px;
    }
  }
}

@media only screen and (max-width: 530px) {
  .talentPage {
    padding: 0 20px;
    .hero-section .col-1 {
      padding: 20px;
      padding-right: 0;
      h1 {
        font-size: 16px;
        margin-bottom: 8px;
      }
      p {
        margin-bottom: 0;
      }
      a.buttonWithArrow {
        margin-top: 16px;
        width: 106px;
        font-size: 9px;
        height: 22px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .talentPage {
    .hero-section {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .whatYouWillGet {
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      &__slider {
        margin-top: 24px;
      }
      &__box {
        svg {
          width: 68px;
          height: 68px;
        }
        div {
          margin-block: 24px 8px;
          font-size: 18px;
          height: auto;
        }
        p {
          font-size: 16px;
        }
      }
    }
    &__howItWorks {
      margin-bottom: 36px;
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }
    }
    .recourses {
      margin-bottom: 36px;
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      &__container {
        margin-top: 20px;
      }
      .resource {
        padding: 16px;
        img {
          width: 60px;
          height: 60px;
        }
        .name {
          font-size: 18px;
          margin-block: 12px;
        }
        .description {
          margin-bottom: 20px;
        }
        button {
          padding: 10px 32px;
          font-size: 12px;
        }
        &__bookmark {
          top: 18px;
          right: 18px;
        }
      }
      .SignUpCard {
        min-height: 400px;
      }
    }
    .employers {
      margin-bottom: 36px;
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }

      &__container {
        margin-top: 24px;
        gap: 16px;
        section {
          padding: 16px;
          p {
            margin-block: 8px;
          }
        }
        .SignUpCard {
          min-height: 350px;
          padding: 12px 32px;
          p {
            font-size: 24px;
            font-weight: 700;
            line-height: 87.6%;
            margin-right: 8px;
          }
          button {
            margin-top: 10px;
            padding: 12px 40px;
          }
        }
      }
    }
    .recommended-jobs-section {
      margin-bottom: 36px;
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }

      .recommendedJobs__container {
        margin-top: 24px;
        gap: 16px;
        section {
          padding: 16px;
          p {
            margin-block: 8px;
          }
        }
        .SignUpCard {
          min-height: 350px;
          padding: 12px 32px;
          p {
            font-size: 24px;
            font-weight: 700;
            line-height: 87.6%;
            margin-right: 8px;
          }
          button {
            margin-top: 10px;
            padding: 12px 40px;
          }
        }
      }
    }
  }
  .talent-at-work {
    margin-bottom: 36px;
    h2 {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 470px) {
  .talentPage .hero-section .col-1 {
    h1 {
      font-size: 14px;
    }
    p {
      font-size: 9px;
    }
    a.buttonWithArrow {
      margin-top: 12px;

      width: 96px;
      font-size: 8px;
      height: 18px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .talentPage {
    .hero-section .col-1 {
      h1 {
        font-size: 12px;
      }
      p {
        font-size: 8px;
      }
      a.buttonWithArrow {
        width: 86px;
        font-size: 6px;
        height: 14px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .talentPage {
    padding: 0 16px;
    .hero-section .col-1 {
      padding: 12px 0 12px 12px;
      padding-right: 0;
      h1 {
        font-size: 10px;
      }
    }
    .whatYouWillGet {
      h2 {
        font-size: 22px;
      }
      &__box {
        div {
          margin-block: 22px 8px;
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    &__howItWorks {
      h2 {
        font-size: 22px;
      }
    }
    .recourses {
      h2 {
        font-size: 22px;
      }

      .resource {
        .name {
          font-size: 16px;
          margin-block: 12px;
        }
        .description {
          margin-bottom: 16px;
        }
        button {
          padding: 10px 32px;
          font-size: 10px;
        }
      }
      .SignUpCard {
        min-height: 315px;
        padding: 12px 24px;
        p {
          font-size: 20px;
        }
        button {
          font-size: 14px;
          width: 128px;
          height: 30px;
          padding: 0;
        }
      }
    }
    .recommended-jobs-section {
      h2 {
        font-size: 22px;
      }
    }
    .employers {
      h2 {
        font-size: 22px;
      }
      &__container {
        section {
          p {
            font-size: 14px;
          }
          button {
            font-size: 14px;
            width: 128px;
            height: 30px;
          }
        }
        .SignUpCard {
          min-height: 270px;
          padding: 12px 24px;
          p {
            margin: 0;
            font-size: 20px;
          }
          button {
            font-size: 14px;
            width: 128px;
            height: 30px;
            padding: 0;
          }
        }
      }
    }
  }
}
