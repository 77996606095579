.resource {
    display: flex;
    padding: 40px 35px;
    border-radius: 24px;
    background: #faf9ff;
    width: 400px;
    flex-direction: column;
    position: relative;
    img{
        width: 75px;
        height: 75px;
        border-radius: 50%;
        object-fit: cover;
    }
    &__bookmark {
        position: absolute;
        top: 32px;
        right: 32px;
        cursor: pointer;
    }
    img {
        width: 75px;
    }
    .name {
        font-size: 24px;
        font-weight: 600;
        color: var(--Text-Primary);
        margin-block: 12px 24px;
    }
    .tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        row-gap: 11px;
        span {
            font-size: 12px;
            font-weight: 400;
            color: var(--Text-Secondary);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            background: #f2f4f7;
            padding: 2px 8px;
        }
    }
    .description {
        font-size: 12px;
        font-weight: 400;
        color: var(--Text-Secondary);
        margin-block: 16px 32px;
    }
    button {
        padding: 12px 40px;
        outline: none;
        border: none;
        background-color: transparent;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        border-radius: 55px;
        border: 0.5px solid var(--Text-Primary);
        background: rgba(30, 30, 47, 0);
        width: fit-content;
        align-self: flex-end;
    }
}
