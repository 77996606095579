.heroCardMB {
  min-height: 120px;
  border-radius: 24px;
  display: flex;
  width: 100%;
  overflow: hidden;
  background-color: #bdafeb;

  &__container {
    padding: 16px;
    padding-right: 8px;
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    &-name {
      color: var(--Text-Primary);
      font-size: 18px;
      font-weight: 700;
    }
    &-paragraph {
      color: var(--Text-Primary);
      font-size: 8px;
      font-weight: 400;
    }
    a {
      color: var(--Text-Secondary);
      font-size: 8px;
      font-weight: 500;
      text-decoration-line: underline;
    }
  }
  img {
    flex: 1;
    width: 25%;
    height: fit-content;
    align-self: flex-end;
    height: auto;
  }
}

@media only screen and (max-width: 480px) {
  .heroCardMB {
    img {
      flex: 1;
      width: 35%;
    }
    &__container {
      width: 65%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .heroCardMB {
    img {
      flex: 1;
      width: 45%;
    }
    &__container {
      width: 55%;
    }
  }
}
