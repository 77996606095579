.partnerScreen {
  max-width: 1248px;
  margin: 0 auto;
  .hero-section {
    display: flex;
    align-items: center;
    border-radius: 24px;
    background-color: #5937cc66;
    margin-bottom: 120px;
    margin-top: 38px;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    background-position: 100%;

    .col-1 {
      padding: 84px 0 60px 60px;
      width: 55%;
      h1 {
        color: var(--dark, #1e1e2f);
        font-family: Space Grotesk;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 114.5%; /* 54.96px */
        margin-bottom: 16px;
      }
      p {
        color: var(--dark-2, #344054);
        font-family: Space Grotesk;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .buttons {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 66px;
        a {
          outline: none;
          border: none;
          width: 202px;
          height: 48px;
          border-radius: 24px;
          background: var(--Text-Primary);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;

          &.invert {
            border: 1px solid var(--Text-Primary);
            background-color: transparent;
            color: var(--Text-Primary);
          }
        }
      }
    }
  }
  .whatYouWillGet {
    margin-bottom: 120px;
    h2 {
      color: var(--dark, #1e1e2f);
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    .carousel-dots {
      display: none;
    }
    &__slider {
      display: none;
    }
    &__container {
      display: flex;
      gap: 24px;
      margin-top: 40px;
      justify-content: space-between;
    }
    &__box {
      display: flex;
      align-items: center;
      flex-direction: column;
      div {
        height: 56px;
        margin-block: 60px 22px;
        color: var(--Text-Primary);
        font-size: 28px;
        font-weight: 600;
        text-align: start;
        align-self: self-start;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        color: var(--Text-secondary);
        text-align: start;
        align-self: self-start;
      }
    }
  }
  &__partnerships {
    display: flex;
    align-items: center;
    gap: 60px;
    margin-bottom: 60px;
    flex-direction: column;
    width: 100%;
    span {
      font-size: 24px;
      font-weight: 600;
      color: var(--Text-Secondary);
    }
  }
  &__programs {
    h2 {
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }
    img {
      width: 352px;
      height: 171px;
      object-fit: cover;
      border-radius: 12px;
    }
    p.paragraph {
      font-size: 20px;
      color: var(--Text-Secondary);
      font-weight: 300;
      width: 600px;
      margin-block: 32px 24px;
    }
    &-container {
      display: flex;
      justify-content: space-between;

      gap: 24px;
      section {
        padding: 24px;
        background-color: var(--Very-Lite-Purple);
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        width: 400px;
        p {
          font-size: 16px;
          font-weight: 600;
          line-height: 150%;
          color: var(--Text-Primary);
          margin-block: 16px 32px;
        }
        button {
          outline: none;
          border: none;
          border-radius: 55px;
          background: var(--Text-Primary);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 156px;
          height: 42px;
        }
      }
    }
  }
  .recourses {
    margin-top: 120px;
    margin-bottom: 69px;

    h2 {
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }
    &__container {
      display: flex;
      margin-top: 40px;
      gap: 24px;
    }
  }
  &__howItWorks {
    margin-bottom: 72px;
    h2 {
      color: var(--Text-Primary);
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    button {
      outline: none;
      border: none;
      padding: 14px 16px;
      border-radius: 24px;
      background-color: var(--Text-Primary);
      font-size: 14.5px;
      font-weight: 500;
      line-height: 20px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      margin: 0 auto;
      margin-top: -60px;
    }
  }
}

@media only screen and (max-width: 1360px) {
  .partnerScreen {
    width: 100%;
    padding: 0 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .partnerScreen .hero-section .col-1 {
    width: 60%;
  }
  .partnerScreen__programs-container section {
    width: 100%;
    flex: 1;
  }
  .partnerScreen .recourses .resource {
    width: 100%;
    flex: 1;
  }
}

@media only screen and (max-width: 1150px) {
  .partnerScreen {
    .whatYouWillGet {
      &__slider {
        display: block;
        margin-top: 36px;
      }
      .carousel-dots {
        display: flex;
      }
      &__container {
        display: none;
      }
      &__box {
        svg {
          margin: 0 auto;
        }
        div {
          font-size: 34px;
          text-align: center;
        }
        p {
          font-size: 24px;
          font-weight: 400;
          text-align: center;
          width: 60%;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .partnerScreen {
    .hero-section {
      margin-bottom: 70px;
      .col-1 {
        width: 65%;
        padding: 60px 0 42px 60px;
      }
    }
    &__partnerships {
      gap: 36px;
      svg {
        height: 42px;
        width: auto;
      }
    }
  }
}

@media only screen and (max-width: 1040px) {
  .partnerScreen .hero-section .col-1 h1 {
    font-size: 42px;
  }
  .partnerScreen__programs-container section.hide {
    display: none;
  }
  .partnerScreen .recourses .resource:first-of-type {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .partnerScreen__howItWorks button {
    display: none;
  }
}

@media only screen and (max-width: 968px) {
  .partnerScreen .hero-section .col-1 {
    width: 60%;
    padding: 48px 0 36px 48px;
    h1 {
      font-size: 38px;
    }
    p {
      font-size: 20px;
    }
    .buttons {
      margin-top: 32px;
      a {
        width: 172px;
      }
    }
  }
}

@media only screen and (max-width: 893px) {
  .partnerScreen {
    .hero-section {
      margin-bottom: 48px;
      .col-1 {
        padding: 36px 0 36px 36px;
        h1 {
          font-size: 34px;
        }
        p {
          font-size: 18px;
        }
      }
    }
    &__partnerships {
      gap: 32px;
      svg {
        height: 36px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .partnerScreen {
    .hero-section .col-1 {
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 16px;
      }
      .buttons {
        margin-top: 24px;
        a {
          width: 152px;
          font-size: 14px;
          height: 40px;
        }
      }
    }
    &__programs-container {
      flex-direction: column;
    }
    .SignUpCard {
      min-height: 350px;
      p {
        font-size: 34px;
      }
    }
    .recourses__container {
      flex-direction: column;
      .SignUpCard {
        min-height: 550px;
      }
    }
  }
}

@media only screen and (max-width: 760px) {
  .partnerScreen {
    .hero-section {
      margin-bottom: 32px;
    }
    &__partnerships {
      gap: 24px;
      span {
        font-size: 20px;
      }
      svg {
        height: 28px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .partnerScreen {
    .hero-section .col-1 {
      padding: 28px;
      padding-right: 0px;
      h1 {
        font-size: 22px;
      }
      p {
        font-size: 14px;
      }
      .buttons {
        a {
          width: 132px;
          font-size: 12px;
          height: 30px;
        }
      }
    }
    .whatYouWillGet {
      margin-bottom: 80px;
      h2 {
        font-size: 36px;
        margin-bottom: 16px;
      }
      &__box {
        p {
          width: 100%;
        }
      }
    }
    &__programs {
      margin-bottom: 80px;
      h2 {
        font-size: 36px;
        margin-bottom: 16px;
      }
      p.paragraph {
        width: 100%;
      }
    }
    .recourses {
      margin-top: 0px;
      margin-bottom: 80px;
      h2 {
        font-size: 36px;
        margin-bottom: 16px;
      }
    }
    &__howItWorks {
      margin-bottom: 80px;
      h2 {
        font-size: 36px;
        margin-bottom: 16px;
      }
    }
  }
}

@media only screen and (max-width: 630px) {
  .whatYouWillGet h2 {
    margin-bottom: 12px;
  }
  .partnerScreen .hero-section {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .partnerScreen {
    .hero-section .col-1 {
      padding: 24px;
      padding-right: 0;
      h1 {
        font-size: 18px;
        margin-bottom: 8px;
      }
      p {
        font-size: 10px;
        width: 80%;
      }
      .buttons {
        margin-top: 24px;
        a {
          width: 106px;
          font-size: 10px;
          height: 24px;
        }
      }
    }
    .whatYouWillGet {
      margin-bottom: 60px;

      h2 {
        font-size: 32px;
        font-weight: 600;
      }
      &__slider {
        margin-top: 24px;
      }
      &__box {
        svg {
          width: 120px;
          height: 120px;
        }
        div {
          margin-block: 36px 16px;
          font-size: 28px;
          height: auto;
        }
        p {
          font-size: 20px;
        }
      }
    }
    &__partnerships {
      gap: 16px;
      margin-bottom: 32px;
      h2 {
        font-size: 32px;
        font-weight: 600;
      }

      svg {
        height: 22px;
      }
    }
    &__programs {
      margin-bottom: 60px;
      h2 {
        font-size: 32px;
        font-weight: 600;
      }
    }
    .SignUpCard p {
      font-size: 30px;
    }
    .recourses {
      margin-bottom: 60px;
      h2 {
        font-size: 32px;
        font-weight: 600;
      }
      .SignUpCard {
        min-height: 500px;
      }
    }
    &__howItWorks {
      margin-bottom: 60px;
      h2 {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }
  }
}

@media only screen and (max-width: 530px) {
  .partnerScreen {
    padding: 0 20px;
    .hero-section .col-1 {
      padding: 20px;
      padding-right: 0;
      h1 {
        font-size: 16px;
        margin-bottom: 8px;
      }
      p {
        margin-bottom: 0;
      }
      .buttons {
        margin-top: 16px;
        a {
          width: 96px;
          font-size: 9px;
          height: 22px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .partnerScreen {
    .hero-section {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .whatYouWillGet {
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      &__slider {
        margin-top: 24px;
      }
      &__box {
        svg {
          width: 68px;
          height: 68px;
        }
        div {
          margin-block: 24px 8px;
          font-size: 18px;
          height: auto;
        }
        p {
          font-size: 16px;
        }
      }
    }
    &__partnerships {
      margin-bottom: 24px;
      span {
        font-size: 16px;
      }
      svg {
        height: 18px;
      }
    }
    &__programs {
      margin-bottom: 36px;
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      p.paragraph {
        display: none;
      }
      &-container {
        margin-top: 24px;
        gap: 16px;
        section {
          padding: 16px;
          p {
            margin-block: 8px;
          }
        }
      }
    }
    .SignUpCard {
      min-height: 300px;
      padding: 12px 32px;
      p {
        font-size: 24px;
        font-weight: 700;
        line-height: 87.6%;
        margin-right: 8px;
      }
      button {
        margin-top: 10px;
        padding: 12px 40px;
      }
    }
    .recourses {
      margin-bottom: 36px;
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      &__container {
        margin-top: 20px;
      }
      .resource {
        padding: 16px;
        img {
          width: 60px;
          height: 60px;
        }
        .name {
          font-size: 18px;
          margin-block: 12px;
        }
        .description {
          margin-bottom: 20px;
        }
        button {
          padding: 10px 32px;
          font-size: 12px;
        }
        &__bookmark {
          top: 18px;
          right: 18px;
        }
      }
      .SignUpCard {
        min-height: 400px;
      }
    }
    &__howItWorks {
      margin-bottom: 36px;
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }
    }
  }
}

@media only screen and (max-width: 470px) {
  .partnerScreen .hero-section .col-1 {
    h1 {
      font-size: 14px;
    }
    p {
      font-size: 9px;
    }
    .buttons {
      margin-top: 12px;
      gap: 8px;
      a {
        width: 84px;
        font-size: 8px;
        height: 18px;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .partnerScreen {
    .hero-section .col-1 {
      h1 {
        font-size: 12px;
      }
      p {
        font-size: 8px;
      }
      .buttons {
        gap: 6px;
        a {
          width: 64px;
          font-size: 6px;
          height: 14px;
        }
      }
    }
    &__partnerships {
      svg {
        height: 16px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .partnerScreen {
    padding: 0 16px;
    .hero-section .col-1 {
      padding: 12px 0 12px 12px;
      padding-right: 0;
      h1 {
        font-size: 10px;
      }
    }
    &__partnerships {
      svg {
        height: 14px;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .partnerScreen {
    .whatYouWillGet {
      h2 {
        font-size: 22px;
      }
      &__box {
        div {
          margin-block: 22px 8px;
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    &__programs {
      h2 {
        font-size: 22px;
      }
      &-container {
        section {
          p {
            font-size: 14px;
          }
          button {
            font-size: 14px;
            width: 128px;
            height: 30px;
          }
        }
      }
      .SignUpCard {
        min-height: 240px;
        padding: 12px 24px;
        p {
          font-size: 20px;
        }
        button {
          font-size: 14px;
          width: 128px;
          height: 30px;
          padding: 0;
        }
      }
    }
    .recourses {
      h2 {
        font-size: 22px;
      }

      .resource {
        .name {
          font-size: 16px;
          margin-block: 12px;
        }
        .description {
          margin-bottom: 16px;
        }
        button {
          padding: 10px 32px;
          font-size: 10px;
        }
      }
      .SignUpCard {
        min-height: 315px;
        padding: 12px 24px;
        p {
          font-size: 20px;
        }
        button {
          font-size: 14px;
          width: 128px;
          height: 30px;
          padding: 0;
        }
      }
    }
    &__howItWorks {
      h2 {
        font-size: 22px;
      }
    }
  }
}
