.SignUpCard {
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 24px;
    flex: 1;
    flex-direction: column;
    padding: 17px 24px;
    justify-content: flex-end;
    p{
      color: black;
      font-size: 24px;
      font-weight: 700;
      margin-right: 42px;
    }
    button{
      outline: none;
      border: none;
      padding: 12px 32px;
      background-color: var(--Text-Primary);
      border-radius: 55px;
      font-size: 14px;
      font-weight: 700;
      color: #F2F4F7;
      width: fit-content;
      margin-bottom: 30px;
     
    }
}