.navbar {
  display: flex;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  &__mb {
    display: none;
  }
  &__col {
    &-1 {
      display: flex;
      align-items: center;
    }
    &-2 {
      display: flex;
      gap: 11px;
    }
  }
  &__seperator {
    margin-inline: 75px 45px;
  }
  &__links {
    display: flex;
    align-items: center;
    gap: 40px;
    a {
      color: var(--Grey);
      font-size: 14px;
      font-weight: 400;
      transition: all 0.2s ease-in-out;
      padding-bottom: 8px;
      border-bottom: 2px solid transparent;
      &:hover {
        border-color: var(--Text-Primary);
        color: var(--Text-Primary);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }
      &.active {
        border-color: var(--Text-Primary);
        color: var(--Text-Primary);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }
  .account-links {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  &__sidebar {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 103vw;
    height: 103vh;
    z-index: 5000;
    display: none;
    flex-direction: column;
    background: #1e1e2f;
    padding: 70px 48px 48px 48px;
    &-links {
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding-bottom: 48px;
      border-bottom: 1px solid #faf9ff;
      a {
        color: #fff;
        font-size: 32px;
        font-weight: 600;
        line-height: normal;
        transition: all 0.2s;
        &.active {
          color: #5937cc;
          text-decoration-line: underline;
        }
      }
    }
    &-buttons {
      display: flex;
      align-items: center;
      gap: 11px;
      margin-top: 32px;
      button {
        outline: none;
        border: none;
        background: transparent;
        padding: 8px 16px;
        border-radius: 28px;
        border: 1px solid var(--Lt-grey, #f2f4f7);
        color: var(--Lt-grey, #f2f4f7);
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        &:first-of-type {
          border-color: #5937cc;
          background-color: #5937cc;
        }
        a {
          text-decoration: none;
          color: var(--Lt-grey, #f2f4f7);
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .navbar {
    height: auto;
    margin-top: 24px;
    &__sidebar {
      display: flex;
    }
  }
  .navbar__col-1,
  .navbar__col-2 {
    display: none;
  }
  .navbar__mb {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    z-index: 6000;
    transition: all 0.3s ease-in-out;
    &.active {
      margin-inline: 16px;
    }
    &-hamburger {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: flex-end;
      transition: all 0.2s ease-in-out;

      section {
        width: 24px;
        height: 2px;
        background-color: #1e1e2f;
        border-radius: 8px;
        transition: all 0.2s ease-in-out;
        &:nth-of-type(2) {
          width: 20px;
        }
      }
      &.active {
        gap: 0px;
        section {
          background-color: white;
          &:first-of-type {
            transform: rotate(45deg);
            margin-bottom: -2px;
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:last-of-type {
            margin-top: -2px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .navbar {
    margin-top: 16px;
    &__logo {
      width: 74px;
    }
    &__sidebar {
      position: absolute;

      padding: 70px 20px 48px 20px;
    }
    &__mb {
      &.active {
        margin-inline: 0px;
      }
    }
  }
}
