.how-we-help-section {
  margin-bottom: 112px;
  h2 {
    color: var(--dark, #1e1e2f);
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 24px;
  }
  .help-section-flex {
    margin-top: 48px;
    display: flex;
    gap: 27px;
    align-items: center;
  }
  .text {
    .text-title {
      color: var(--text-secondry, #344054);
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 104px;
    }
  }
  .cards {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 46px;
    .card {
      display: flex;
      gap: 24px;
      .svg-wrapper {
        border-radius: 24px;
        background: var(--very-lite-purple, #faf9ff);
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 80px;
      }
      div {
        & > p:first-of-type {
          color: var(--text-primary, #1e1e2f);
          font-size: 28px;
          font-weight: 600;
          line-height: 100.9%; /* 28.252px */
          margin-bottom: 14px;
        }
        p {
          color: var(--text-secondry, #344054);
          font-size: 20px;
          font-weight: 300;
          line-height: 100.9%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .how-we-help-section {
    .help-section-flex > img {
      width: 400px;
    }
    .cards .card {
      .svg-wrapper,
      svg {
        width: 60px;
        height: 60px;
        flex: none;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .how-we-help-section {
    .help-section-flex > img {
      display: none;
    }
    .text {
      .text-title {
        margin-left: 0px;
      }
    }
    .cards .card {
      .svg-wrapper,
      svg {
        width: 80px;
        height: 80px;
        flex: none;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .how-we-help-section {
    margin-bottom: 80px;
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 630px) {
  .how-we-help-section {
    h2 {
      margin-bottom: 12px;
    }
    .help-section-flex {
      margin-top: 16px;
    }
    .cards {
      margin-top: 24px;
      .card {
        div p {
          line-height: normal;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .how-we-help-section {
    margin-bottom: 60px;
    h2 {
      font-size: 32px;
      font-weight: 600;
    }
    .text {
      .text-title {
        font-size: 20px;
      }
    }
    .help-section-flex {
      margin-top: 16px;
    }
    .cards {
      margin-top: 24px;
      gap: 32px;
      .card {
        gap: 16px;
        .svg-wrapper,
        svg {
          width: 60px;
          height: 60px;
          flex: none;
        }
        div p {
          line-height: normal;
          font-size: 18px;
          color: #111;
          &:first-of-type {
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .how-we-help-section {
    margin-bottom: 36px;
    h2 {
      font-size: 24px;
      margin-bottom: 8px;
    }
    .text {
      .text-title {
        font-size: 16px;
      }
    }

    .cards {
      margin-top: 16px;
      gap: 10px;
      .card {
        gap: 16px;
        .svg-wrapper,
        svg {
          width: 48px;
          height: 48px;
          flex: none;
        }
        div p {
          line-height: normal;
          font-size: 12px;
          &:first-of-type {
            font-size: 18px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .how-we-help-section {
    h2 {
      font-size: 22px;
    }
    .text {
      .text-title {
        font-size: 14px;
      }
    }

    .cards {
      .card {
        div p {
          font-size: 10px;
          &:first-of-type {
            font-size: 16px;
          }
        }
      }
    }
  }
}
