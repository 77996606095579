.new-roles-section {
  display: flex;
  padding: 60px;
  border-radius: 36px;
  background: var(--Lite-Peach);
  margin-bottom: 106px;
  justify-content: space-between;
  div {
    flex-basis: 60%;
    gap: 64px;
    h2 {
      color: var(--dark-1, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 100.9%; /* 64.576px */
      margin-bottom: 40px;
    }
    p {
      color: var(--dark-1, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 100.9%; /* 24.216px */
      margin-bottom: 52px;
    }
    input {
      outline: none;
      border: none;
      background: transparent;
      border-radius: 36px;
      border: 1px solid var(--dark-1, #1e1e2f);
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      color: var(--Text-Primary);
      width: 345px;
      padding: 15px 18px;
      &::placeholder {
        color: var(--Text-Primary);
      }
    }
    .input-and-button {
      display: flex;
      gap: 40.5px;
      button {
        background-color: transparent;
        color: white;
        border-radius: 100px;
        background: var(--Text-Primary);
        padding: 12px 24px;
        border: 1px solid var(--Text-Primary);
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        cursor: pointer;
      }
    }
  }
  img {
    height: fit-content;
    margin: auto;
  }
}

@media only screen and (max-width: 1075px) {
  .new-roles-section {
    padding: 60px 35px;
  }
}

@media only screen and (max-width: 1000px) {
  .new-roles-section {
    flex-direction: column-reverse;
    gap: 32px;
    div {
      input {
        width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .new-roles-section {
    margin-bottom: 80px;
    div {
      h2 {
        font-size: 48px;
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 16px;
      }
      .input-and-button {
        gap: 12px;
        input {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .new-roles-section{
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 500px) {
  .new-roles-section {
    padding: 38px 24px;
    margin-bottom: 36px;
    div {
      h2 {
        font-size: 32px;

        font-weight: 700;
        line-height: 100.9%;
      }
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
      .input-and-button {
        button {
          padding: 8px 16px;
          font-size: 14px;
          line-height: normal;
          font-weight: 500;
        }
      }
      input {
        padding: 8px 10px;
        height: 34px;
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
      }
    }
    img {
      width: 60%;
    }
  }
}

@media only screen and (max-width: 355px) {
  .new-roles-section {
    div {
      h2 {
        font-size: 28px;
        line-height: normal;
      }
      p {
        font-size: 12px;
      }
      .input-and-button {
        button {
          font-size: 12px;
        }
      }
      input {
        font-size: 12px;
      }
    }
    img {
      width: 60%;
    }
  }
}
