.homepage {
  max-width: 1248px;
  margin: 0 auto;
  &-hero {
    &-section {
      margin-bottom: 120px;
      display: flex;
      flex-direction: column;
      gap: 60px;
    }
    &-card-mb {
      display: none;
    }
    &-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 10px;
      max-width: 824px;
      margin: 60px auto 0px auto;
      a {
        display: flex;
        height: 48px;
        padding-block: 14px;
        padding-inline: 16px 12px;
        justify-content: space-between;
        align-items: center;
        border-radius: 24px;
        background: var(--Purple);
        width: 200px;
        margin: 0 auto;
        color: #fff;
        font-size: 14.5px;
        font-weight: 500;
        line-height: 140%;
        transition: all 0.2s ease-in-out;
        svg {
          transition: all 0.2s ease-in-out;
        }
        &:hover {
          background-color: var(--Text-Primary);
          svg {
            transform: translateX(-4px);
          }
        }
      }
    }
    &-text {
      color: var(--Text-Primary);
      text-align: center;
      font-size: 64px;
      font-weight: 700;
      line-height: 105.6%;
      text-align: center;
    }
    &-subtext {
      color: var(--Text-Secondary);
      text-align: center;
      font-size: 24px;
      font-weight: 400;
    }
  }

  .whoWeAre {
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-radius: 24px;
    gap: 24px;
    align-items: center;
    width: 100%;
    background-image: url("../../assets/images/whoWeAre.png");
    background-position: top;
    background-size: cover;
    margin-bottom: 120px;
    div {
      font-size: 36px;
      font-weight: 600;
      color: var(--Text-Primary);
    }
    p {
      font-size: 24px;
      font-weight: 400;
      color: var(--Text-Primary);
      text-align: center;
      width: 640px;
    }
    a.buttonWithArrow {
      background: var(--Purple);
    }
  }

  &__howItWorks {
    margin-bottom: 40px;
    h2 {
      color: var(--Text-Primary);
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    button {
      outline: none;
      border: none;
      padding: 14px 16px;
      border-radius: 24px;
      background-color: var(--Text-Primary);
      font-size: 14.5px;
      font-weight: 500;
      line-height: 20px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      margin: 0 auto;
      margin-top: -60px;
    }

  }

  .homepage-hero-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    height: max-content;
  }

  .community-impact-section {
    margin-bottom: 120px;
    h2 {
      margin-bottom: 24px;
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .community-impact-section__details {
      display: flex;
      align-items: center;
      gap: 110px;
    }
    .top-para {
      margin-top: 24px;
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 503px;
    }
    h3 {
      color: var(--grey, #999);
      font-family: Space Grotesk;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 48px;
      padding-top: 60px;
    }
    .community-impact-section__cards > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      .card {
        padding: 16px;
        border-radius: 24px;
        background: var(--lite-yellow, #feedcc);
        box-shadow: 0px 0px 4px 0px rgba(11, 1, 53, 0.04);
        width: 294px;
        height: 182px;
        position: relative;
        display: flex;
        flex-direction: column;
        span {
          color: var(--Text-Primary);
          font-size: 40px;
          font-weight: 700;
        }
        p {
          color: var(--Text-Primary);
          font-size: 12px;
          transform: scale(0.9);
          font-weight: 400;
          margin-left: -12px;
        }
        img {
          position: absolute;
          right: 15px;
          bottom: 5px;
        }
      }
    }
  }
  .how-it-works-section {
    margin-bottom: 120px;
    h2 {
      margin-bottom: 24px;
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .howItWorks__container {
      margin-top: 60px;
      display: flex;
      align-items: center;
      flex-direction: column;

      gap: 25px;
      header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }
      main {
        display: flex;
        gap: 24px;
        div {
          display: flex;
          align-items: center;
          flex-direction: column;
          flex: 1;
          gap: 24px;
          // justify-content: center;
          span {
            color: var(--Text-Primary);
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            height: 56px;
          }
          p {
            color: #111;
            text-align: center;
            font-family: Space Grotesk;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }
  }

  .whoShouldJoin {
    display: flex;
    flex-direction: column;
    margin-bottom: 120px;
    h2 {
      margin-bottom: 24px;
      color: var(--Text-Primary);
      font-size: 48px;
      font-weight: 600;
    }
    &__container {
      display: flex;
      flex-direction: column;
      gap: 60px;
      margin-top: 92px;
    }
    .carousel-dots {
      display: none;
    }
    &__slider {
      display: none;
    }
  }

  .full-width-cards {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-bottom: 120px;
    .talent-card {
      border-radius: 36px;
      display: flex;
      justify-content: space-between;
      padding: 60px;
      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        p {
          margin-top: auto;
          margin-bottom: 28px;
        }
      }
      h3 {
        color: var(--text-primary, #1e1e2f);
        font-family: Space Grotesk;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 100.9%; /* 36.324px */
      }
      p {
        color: var(--text-secondry, #344054);
        font-family: Space Grotesk;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 100.9%; /* 24.216px */
      }
    }
    .peach {
      background: #fdceb5;
    }
    .purple {
      background: #bdafeb;
    }
  }
  .ceo-message-section {
    margin-bottom: 120px;
    h2 {
      color: var(--dark, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }
    & > div {
      margin-top: 75px;
      display: flex;
      justify-content: space-between;
    }
    .ceo-message-right {
      border-radius: 24px;
      background: var(--brand-colors-yellow, #feedcc);
      padding: 60px;
      max-width: 40vw;
    }
    .ceo-message-text {
      color: var(--text-primary, #1e1e2f);
      font-family: Space Grotesk;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .salary-comparison {
      display: flex;
      gap: 40px;
      margin-top: 46px;
      margin-bottom: 54px;
      div {
        & > p:first-of-type {
          color: var(--dark, #1e1e2f);
          font-family: Space Grotesk;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        p {
          color: var(--dark, #1e1e2f);
          font-family: Space Grotesk;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
.reviews-section {
  margin-bottom: 102px;
  h2 {
    margin-bottom: 24px;
    color: var(--dark, #1e1e2f);
    font-family: Space Grotesk;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .top-para {
    margin-top: 30px;
    width: 504px;
    color: #111;
    font-family: Space Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1360px) {
  .homepage {
    width: 100%;
    padding: 0 30px;
  }
}

@media only screen and (max-width: 1210px) {
  .homepage .community-impact-section .community-impact-section__cards > div .card {
    width: 250px;
  }
  .homepage .community-impact-section .community-impact-section__details {
    gap: 32px;
  }
}
@media only screen and (max-width: 1150px) {
  .homepage .how-it-works-section .howItWorks__container header {
    width: 70%;
  }
}

@media only screen and (max-width: 1100px) {
  .homepage .how-it-works-section .howItWorks__container header {
    width: 70%;
  }
}

@media only screen and (max-width: 1000px) {
  .community-impact-section__image {
    display: none;
  }
  .homepage .community-impact-section .top-para {
    max-width: unset;
  }
  .homepage .community-impact-section .community-impact-section__cards {
    width: 100%;
    > div {
      grid-template-rows: 1fr 1fr;
    }
  }
  .homepage .community-impact-section .community-impact-section__cards > div .card {
    width: 100%;
  }

  .homepage__howItWorks {
      .how-it-works-section .icons__container {
       gap: 3.7vh;
      }
  }
}

@media only screen and (max-width: 950px){
    .homepage__howItWorks {
        .how-it-works-section .icons__container {
         gap: 2vh;
        }
    }
}

@media only screen and (max-width: 850px) {
  .homepage-hero-text {
    font-size: 48px;
  }
  .homepage-hero-subtext {
    font-size: 20px;
  }
  .homepage-hero-container {
    gap: 24px;
    margin-top: 42px;
    a {
      height: 36px;
      width: 160px;
    }
  }
  .homepage-hero-section {
    margin-bottom: 80px;
  }
  .homepage .whoWeAre {
    margin-bottom: 80px;
  }
  .homepage .community-impact-section {
    margin-bottom: 80px;
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }
  }
  .homepage {
    &__howItWorks {
      margin-bottom: 80px;
      h2 {
        font-size: 36px;
        margin-bottom: 16px;
      }
    }
    .whoShouldJoin {
      margin-bottom: 80px;
      h2 {
        font-size: 36px;
        margin-bottom: 16px;
      }
      &__container {
        margin-top: 36px;
        gap: 36px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .homepage {
    .whoWeAre {
      div {
        font-size: 32px;
        font-weight: 600;
        color: var(--Text-Primary);
      }
      p {
        font-size: 20px;

        width: 100%;
      }
      a.buttonWithArrow {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (max-width: 780px){
    .homepage__howItWorks {
        .how-it-works-section .icons__container {
         gap: 2.9vh;
        }
    }
}

@media only screen and (max-width: 760px) {
  .homepage-hero-section {
    gap: 48px;
  }
}

@media only screen and (max-width: 720px) {
  .reviews-section {
    margin-bottom: 80px;
    h2 {
      font-size: 36px;
      margin-bottom: 16px;
    }
  }
  .homepage {
    &-hero {
      &-section {
        gap: 32px;
      }
      &-container {
        gap: 16px;
        padding: 0;
        h1 {
          font-size: 36px;
        }
      }
    }
  }
  .homepage .homepage-hero-card {
    gap: 14px;
  }
  .homepage {
    .whoShouldJoin {
      &__container {
        display: none;
      }
      &__slider {
        display: initial;
        margin-top: 24px;
      }
      .carousel-dots {
        display: initial;
      }
    }
  }
}

@media only screen and (max-width: 700px){
    .homepage__howItWorks {
        .how-it-works-section .icons__container {
         gap: 3.5vh;
        }
    }
}

@media only screen and (max-width: 618px){
    .homepage__howItWorks {
        .how-it-works-section .icons__container {
         gap: 4.5vh;
        }
    }
}

@media only screen and (max-width: 600px) {
  .reviews-section {
    margin-bottom: 60px;
    h2 {
      font-size: 32px;
      margin-bottom: 16px;
    }
    .top-para {
      width: 100%;
    }
  }
  .homepage {
    &-hero-card {
      display: none !important;
      &-mb {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
    &__howItWorks {
      margin-bottom: 60px;
      h2 {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }
    &-hero-section {
      margin-bottom: 60px;
    }

    .whoShouldJoin {
      margin-bottom: 60px;
      h2 {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }

    .whoWeAre {
      margin-bottom: 60px;
      padding: 24px;
      gap: 24px;
      div {
        font-size: 30px;
      }
      p {
        font-size: 16px;
      }
      a.buttonWithArrow {
        margin-top: 0;
        width: 160px;
        height: 42px;
      }
    }
    .community-impact-section {
      margin-bottom: 60px;
      h2 {
        font-size: 32px;
        margin-bottom: 16px;
      }
      .top-para {
        margin-top: 16px;
        font-size: 22px;
      }
      h3 {
        margin-bottom: 36px;
        padding-top: 36px;
      }
      &__cards > div .card img {
        position: absolute;
        right: -4px !important;
        transform: scale(0.8);
        bottom: -8px !important;
      }
    }
  }
}

@media only screen and (max-width: 585px){
    .homepage__howItWorks {
        .how-it-works-section .icons__container {
         gap: 5.3vh;
        }
    }
}


@media only screen and (max-width: 530px) {
  .homepage {
    width: 100%;
    padding: 0 20px;
    background-size: 300% !important;
  }
}

@media only screen and (max-width: 500px) {
  .reviews-section {
    margin-bottom: 36px;
    h2 {
      font-size: 24px;
      margin-bottom: 8px;
    }
    .top-para {
      font-size: 16px;
      font-weight: 400;
      margin-block: 12px 24px;
    }
  }
  .homepage {
    &-hero-container {
      gap: 8px;
      h1 {
        font-size: 32px;
        font-weight: 700;
        line-height: 105.6%;
      }
      .homepage-hero-subtext {
        font-size: 16px;
        font-weight: 400;
      }

      a {
        margin-top: 8px;
      }
    }
    .whoShouldJoin {
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      &__slider {
        margin-top: 12px;
      }
    }
    .whoShouldJoin {
      margin-bottom: 36px;
    }
    &-hero-section {
      margin-bottom: 36px;
    }
    &__howItWorks {
      margin-bottom: 36px;
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      .how-it-works-section .icons__container {
       gap: 1.7vh;
      }
    }
    .community-impact-section {
      margin-bottom: 36px;
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      .top-para {
        font-size: 16px;
      }
      h3 {
        margin-bottom: 24px;
        padding-top: 16px;
        font-size: 16px;
        font-weight: 600;
      }
      &__cards > div {
        .card {
          height: 115px !important;
          span {
            font-size: 24px !important;
          }
          p {
            font-size: 10px !important;
          }
          img {
            position: absolute;
            right: -21px !important;
            transform: scale(0.4);
            bottom: -34px !important;
          }
          &:nth-of-type(3) {
            img {
              bottom: 0px !important;
              right: -32px !important;
            }
          }
        }
      }
    }
    .community-impact-section .community-impact-section__cards > div {
      gap: 8px;
    }

    .whoWeAre {
      margin-bottom: 36px;
      padding: 24px 16px;
      gap: 16px;
      div {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
      a.buttonWithArrow {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 483px){
    .homepage__howItWorks {
        .how-it-works-section .icons__container {
         gap: 2.5vh;
        }
    }
}
@media only screen and (max-width: 472px){
    .homepage__howItWorks {
        .how-it-works-section .icons__container {
         gap: 3.3vh;
        }
    }
}

@media only screen and (max-width: 409px){
    .homepage__howItWorks {
        .how-it-works-section .icons__container {
         gap: 4.4vh;
        }
    }
}

@media only screen and (max-width: 370px){
    .homepage__howItWorks {
        .how-it-works-section .icons__container {
         gap: 5vh;
        }
    }
}


@media only screen and (max-width: 350px) {
  .reviews-section {
    h2 {
      font-size: 22px;
    }
    .top-para {
      font-size: 14px;
    }
  }
  .homepage {
    &__howItWorks {
      h2 {
        font-size: 22px;
      }
      .how-it-works-section .icons__container {
       gap: 3.3vh;
      }
    }
    .whoShouldJoin {
      h2 {
        font-size: 22px;
      }
    }
    &-hero-container {
      gap: 8px;
      h1 {
        font-size: 28px;
        font-weight: 700;
        line-height: 105.6%;
      }
      .homepage-hero-subtext {
        font-size: 14px;
        font-weight: 400;
      }
      a {
        margin-top: 8px;
      }
    }
    .whoWeAre {
      div {
        font-size: 22px;
      }
      p {
        font-size: 14px;
      }
      a.buttonWithArrow {
        display: none;
      }
    }
  }
  .community-impact-section {
    h2 {
      font-size: 22px;
    }
  }
}
