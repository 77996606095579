.verify-email-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px);
  .verify-email {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    color: var(--dark, #1e1e2f);
    text-align: center;
    font-family: Space Grotesk;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 114.5%; /* 45.8px */
    margin-top: 8px;
    margin-bottom: 32px;
  }
  p {
    color: var(--text-primary, #1e1e2f);
    text-align: center;
    font-family: Space Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 32px;
  }
  .change-resend-mail {
    color: var(--text-primary, #1e1e2f);
    text-align: center;
    font-family: Space Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    a {
      color: var(--brand-colors-color, #5937cc);
    }
  }
}
