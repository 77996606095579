.heroCard {
    background-repeat: no-repeat;
    background-size: cover;
    height: 467px;
    border-radius: 24px;
    display: flex;
    padding: 34.5px 30px;
    background-position: center;
    width: 100%;
    align-items: flex-end;
    overflow: hidden;
    &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        div {
            color: var(--Off-White);
            font-size: 36px;
            font-weight: 700;
            line-height: 25px;
        }

        a {
            color: var(--Peach);
            font-size: 16px;
            font-weight: 500;
            text-decoration: underline;
            display: flex;
            align-items: flex-end;
            svg {
                margin-bottom: 2px;
            }
        }
        &-hover {
            display: flex;
            flex-direction: column;
            gap: 16px;
            div {
                color: var(--Off-White);
                font-size: 36px;
                font-weight: 700;
                line-height: 25px;
            }
            p {
                color: var(--Off-White);
                font-size: 16px;
                font-weight: 400;
            }
            a {
                color: white;
                font-size: 16px;
                font-weight: 500;
                text-decoration: underline;
                display: flex;
                align-items: flex-end;
                svg {
                    margin-bottom: 2px;
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .heroCard {
        width: 32%;
        height: 300px;
        background-position: unset;
    }
}

@media only screen and (max-width: 850px) {
    .heroCard {
        padding: 34.5px 16px;
        &__container {
            gap: 8px;
            div {
                font-size: 28px;
            }
            a {
                font-size: 12px;
            }
        }
    }
}

@media only screen and (max-width: 760px) {
    .heroCard {
        height: 250px;
        padding: 18.5px 16px;
        div {
            font-size: 24px;
        }
    }
}

@media only screen and (max-width: 760px) {
    .heroCard {
        height: 189px;
        padding: 18.5px 16px;
        &__container {
            gap: 6px;
            div {
                font-size: 22px;
            }
            a {
                font-size: 10px;
                svg {
                    width: 12px;
                }
            }
        }
    }
}
