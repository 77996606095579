@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
:root {
  --Text-Primary: #1e1e2f;
  --Text-Secondary: #344054;
  --Lite-Peach: #fdceb5;
  --Lite-Purple: #bdafeb;
  --Very-Lite-Purple: #faf9ff;
  --Lite-Yellow: #feedcc;
  --Lite-Grey: #f2f4f7;
  --Off-White: #ededed;
  --Dark-Grey: #999999;
  --Peach: #eba26e;
  --Purple: #5937cc;
  --Dark-1: #1e1e2f;
  --Dark-2: #344054;
  --Brand-Colors-Peach: #fbad84;
  --Brand-Colors-Yellow: #feedcc;
  --Dark: #1e1e2f;
  --FFFFFF: #ffffff;
  --Grey: #9aa0a9;
  --Neutral-600: #6f6c90;
  --Purple: #5937cc;
  --Solid-Primary-20: #d0d9fa;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--Text-Primary);
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  cursor: pointer;
  outline: none;
  border: none;
}

a {
  text-decoration: none;
}

// Components

.link-button {
  padding: 8px 16px;
  border-radius: 28px;
  background-color: var(--Text-Primary);
  color: var(--Off-White);
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--Text-Primary);
  &:hover {
    padding-inline: 24px;
  }
  &.inverted {
    background-color: transparent;
    color: var(--Text-Primary);
  }
}

.buttonWithArrow {
  border-radius: 24px;
  background: var(--Text-Primary);
  display: flex;
  height: 48px;
  padding: 0 12px 0 16px;
  align-items: center;
  justify-content: space-between;
  width: 202px;
  line-height: 1;
  color: white;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 140%;
  margin-top: 24px;
  transition: all 0.2s ease-in-out;
  svg {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    background-color: var(--Text-Primary);
    svg {
      transform: translateX(-4px);
    }
  }
}

// transititons

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #5937cc;
  transform-origin: bottom;
  z-index: 8000;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #5937cc;
  transform-origin: bottom;
  z-index: 8000;

}

.slick-track{
  align-items: center;
    display: flex !important;
}