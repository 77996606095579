.custom-input {
  label {
    width: 100%;
  }
  .label-text {
    color: var(--text-primary, #1e1e2f);
    font-family: Space Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 7px;
  }
  input {
    border-radius: 12px;
    border: 1px solid rgba(102, 102, 102, 0.35);
    width: 100%;
    padding: 6px;
  }
}
