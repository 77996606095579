.fullWidthCard {
    display: flex;
    height: 445px;
    border-radius: 36px;
    &__content {
        display: flex;
        flex-direction: column;
        width: 55%;
        padding: 50px 50px 60px 60px;
        height: 100%;
        div.title {
            font-size: 36px;
            font-weight: 700;
            color: var(--Text-Primary);
            margin-bottom: auto;
        }
        p {
            font-size: 24px;
            font-weight: 400;
            color: var(--Text-Secondary);
            line-height: 100.9%;
        }
    }
    &__image {
        width: 55%;
        height: 100%;
        background-size: cover;
        background-position: 100%;
    }
}

@media only screen and (max-width: 1250px) {
    .fullWidthCard {
        &__image {
            margin-block: auto;
            width: 55%;
            height: 80%;
            background-size: cover;
            background-position: 100%;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .fullWidthCard {
        height: auto;
        &__content {
            width: 100%;
            div.title {
                margin-bottom: 24px;
            }
            p {
                margin-bottom: 16px;
                line-height: normal;
            }
            a {
                margin-top: 0 !important;
            }
        }
        &__image {
            display: none;
        }
    }
}

@media only screen and (max-width: 720px) {
    .fullWidthCard__content {
        padding: 36px;
    }
}

@media only screen and (max-width: 600px) {
    .fullWidthCard__content {
        padding: 36px;
    }
}

@media only screen and (max-width: 500px) {
    .fullWidthCard {
        &__content {
            padding: 24px;
            display: flex;
            flex-direction: column;
            height: 100%;
            div.title {
                font-size: 22px;
                font-weight: 700;
                color: var(--Text-Primary);
                margin-bottom: 8px;
            }
            p {
                font-size: 16px;
                font-weight: 400;
                color: var(--Text-Secondary);
            }
            a {
                width: 156px;
                height: 32px;
                font-size: 14px;
            }
        }
    }
}

@media only screen and (max-width: 350px) {
    .fullWidthCard {
        &__content {
            div.title {
                font-size: 20px;
            }
            p {
                font-size: 14px;
            }
        }
    }
}
