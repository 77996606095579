.ourTalent {
    border-radius: 24px;
    background: var(--Very-Lite-Purple);
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 42.5px;
    padding: 24px;
    &__profile {
        display: flex;
        align-items: center;
        gap: 9px;
        img {
            width: 72px;
            height: 72px;
            border-radius: 50%;
        }
        .content {
            display: flex;
            flex-direction: column;
            gap: 5px;
            div {
                font-size: 24px;
                font-weight: 600;
                color: var(--Text-Primary);
                line-height: 24px;
            }
            span {
                font-weight: 400;
                font-size: 12px;
                color: var(--Text-Primary);
            }
        }
    }
    &__details {
        display: flex;
        justify-content: space-between;
        div {
            display: flex;
            flex-direction: column;
            gap: 4px;
            font-size: 12px;
            font-weight: 400;
            color: var(--Text-Secondary);
            span {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 12px;
                font-weight: 600;
                color: var(--Text-Primary);
            }
        }
    }
    .buttons {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 7px;
        margin-top: 30px;
        button {
            border-radius: 55px;
            background: var(--Text-Primary);
            outline: none;
            border: none;
            color: var(--Lite-Grey);
            font-size: 14px;
            font-weight: 700;
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 42px;
            border: 1px solid var(--Text-Primary);
            &.invert {
                background-color: transparent;
                color: var(--Text-Primary);
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .ourTalent {
        padding: 24px;
        gap: 32px;
        &__details {
            margin-top: 24px;
        }
        .buttons {
            margin-top: 0;
        }
    }
}

@media only screen and (max-width: 390px) {
    .ourTalent {
        &__details {
            div {
                font-size: 10px;
            }
        }
    }
}

@media only screen and (max-width: 350px) {
    .ourTalent {
        &__details {
            div {
                font-size: 8px;
                span {
                    font-size: 10px;
                }
            }
        }
    }
}
