.how-it-works-section {
  margin-bottom: 120px;
  h2 {
    color: var(--dark, #1e1e2f);
    font-family: Space Grotesk;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
  }
  .icons__container {
    display: none;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    margin-top: 52px;
    svg {
      min-width: 60px;
      min-height: 60px;
      max-width: 60px;
      max-height: 60px;
    }
  }
  .grid-container {
    margin-top: 62px;
    display: grid;
    grid-template-columns: 1fr auto 1fr 1fr auto 1fr 1fr auto 1fr;
    grid-gap: 10px;
  }

  .grid-item:nth-child(1) {
    grid-column: 1 / span 1;
  }

  .grid-item:nth-child(2) {
    grid-column: 2 / span 1;
  }

  .grid-item:nth-child(3) {
    grid-column: 3 / span 2;
  }

  .grid-item:nth-child(4) {
    grid-column: 5 / span 1;
  }

  .grid-item:nth-child(5) {
    grid-column: 6 / span 2;
  }

  .grid-item:nth-child(6) {
    grid-column: 8 / span 1;
  }

  .grid-item {
    // background-color: lightgray;
    padding: 10px;
    text-align: center;
  }

  .horizontal-line {
    height: 1px;
    // background-color: black;
    background-color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    align-self: center;
  }

  .tranparent {
    background: transparent;
  }
  .colums {
    grid-column: span 3; /* The div will span across 2 columns */
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    & > p:first-of-type {
      color: #111;
      text-align: center;
      font-family: Space Grotesk;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 100.9%; /* 28.252px */
      height: 56px;
    }
    p {
      color: #111;
      text-align: center;
      font-family: Space Grotesk;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%;
      max-width: 400px;
    }
  }
  .svg-wrapper {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 24px;
    background: var(--lite-purple, #bdafeb);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1000px) {
  .how-it-works-section {
    .icons__container {
      display: flex;
    }
    display: flex;
    gap: 16px;
    .grid-container {
      .grid-item {
        display: none;
      }
      display: flex;
      flex-direction: column;
      gap: 85px;
      justify-content: space-evenly;
      .colums {
        text-align: start;
        align-items: flex-start;
        gap: 12px;
        p {
          &:first-of-type {
            height: auto;
          }
          text-align: start;
          max-width: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .how-it-works-section {
    .grid-container {
      gap: 60px;
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: 720px) {
  .how-it-works-section {
    margin-bottom: 0px !important;
  }
}

@media only screen and (max-width: 600px) {
  .how-it-works-section {

    .icons__container {
      margin-top: 25px;
    }
    .grid-container {
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 566px) {
  .how-it-works-section {
    .grid-container {
      gap: 46px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .how-it-works-section {
    .icons__container {
      gap: 6px;
      svg {
        min-width: 48px;
        min-height: 48px;
        max-width: 48px;
        max-height: 48px;
      }
    }
    .grid-container {
      margin-top: 30px;
      gap: 14px;

      .colums p {
        &:first-of-type {
          font-size: 22px;
          font-weight: 700;
        }
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .how-it-works-section {
    .grid-container {
      margin-top: 30px;
      gap: 14px;

      .colums p {
        &:first-of-type {
          font-size: 20px;
          font-weight: 700;
        }
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
